import { Box, Container, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Line from "../components/DefinitionComponents/Complete-Components/Line";
import InViewTrigger from "../components/DefinitionComponents/Utility/UX/InViewTrigger";
import GImage from "../components/DefinitionComponents/GImage";
import Layout from "../components/layout/Layout";
import AnimatedText from "../components/DefinitionComponents/Complete-Components/AnimatedText";
import ImgTextModule from "../components/DefinitionComponents/ImageTextModule";

const Index = () => {
	const { setColorMode } = useColorMode();
	useEffect(() => {
		setColorMode("light");
	});
	return (
		<Layout
			title={"Contact"}
			description={
				"Contact"
			}>
			<ImgTextModule
				src={"14mini.mp4"}
				delay={0.3}
				duration={0.5}
				threshold={0.3}
				initialY={10}
				initialX={10}
				video={true}
				h="85vh"
				mediaOpacity={0.7}
				bgColor="brand.gray.100"
				px={8}
			>
				<Container variant="layoutContainer" mt={8} pt={24}>
					<Box pb={24}>
						<GImage
							width="10%"
							src="dfn-logo-asia-white-small.svg"
						/>

						<InViewTrigger threshold={0.1} triggerOnce={false}>
							{(inView) => (
								<Line
									maxW="50%"
									duration={1}
									delay={0}
									inView={inView}
									bg={"brand.green.primary"}
									h={"1px"}
									my={2}
								/>
							)}
						</InViewTrigger>
						<AnimatedText
							tag={"h2"}
							mb={4}
							lineHeight="undercut"
							text={"Contact"}
							color={"brand.green.primary"}
							size={{ base: "3xl", md: "3xl", lg: "7xl" }}
							maxW={"4xl"}
							threshold={0.2}
							triggerOnce={true}
							staggerChildren={0.02}
							id={"dfn1-titlesub1"}
						/>
						<AnimatedText
							tag={"h3"}
							mb={4}
							lineHeight="undercut"
							text={"Get in touch with us!"}
							color={"brand.gray.almostwhite"}
							size={{ base: "3xl", md: "3xl", lg: "5xl" }}
							maxW={"4xl"}
							threshold={0.2}
							triggerOnce={true}
							staggerChildren={0.02}
							id={"dfn1-titlesub1"}
						/>
					</Box>
				</Container>
			</ImgTextModule>
			<Container variant="layoutContainer" py={8} px={8} pt={16}>
				<AnimatedText
					tag={"h4"}
					mb={4}
					lineHeight="undercut"
					text={"Definition Digital Products Development + Platforms GmbH"}
					color={"brand.gray.300"}
					size={{ base: "xl", md: "xl", lg: "xl" }}
					maxW={"xl"}
					threshold={0.2}
					triggerOnce={true}
					staggerChildren={0.02}
					id={"dfn1-titlesub1"}
				/>
				<div>
					<p>
						<strong>Adresse:</strong><br/>
						Am Hillernsen Hamm 51<br/>
						26441 Jever<br/>
						Deutschland/Germany
					</p>			
					<br/>
					<p>
						<strong>Ansprechpartner & Kontakt:</strong><br/>
						Christoph Gerken (Geschäftsführung)<br/>
						Ralf Fischer (Prokurist)<br/>
						E-Mail: hello@definition.group<br/>
						Telefon: +49-4461-918-7684 <br/>
					</p>	
				</div>
			</Container>
			<Container variant="layoutContainer" py={8} px={8}>
				<AnimatedText
					tag={"h4"}
					mb={4}
					lineHeight="undercut"
					text={"Definition Digital Research Development Division Spain SL"}
					color={"brand.gray.300"}
					size={{ base: "xl", md: "xl", lg: "xl" }}
					maxW={"xl"}
					threshold={0.2}
					triggerOnce={true}
					staggerChildren={0.02}
					id={"dfn1-titlesub1"}
				/>
				<div>
					<p>
						<strong>Dirección:</strong><br/>
						C/ Agustin Millares 18<br/>
						Las Palmas,
						Gran Canaria, España<br/>
					</p>			
					<br/>
					<p>
						<strong>Persona de contacto</strong><br/>
						Martin Adelstein (gerente)<br/>
						Christoph Gerken (gerente)<br/>
						E-Mail: hola@definition.group<br/>
					</p>	
				</div>
			</Container>
			<Container variant="layoutContainer" py={8} px={8}>
				<AnimatedText
					tag={"h4"}
					mb={4}
					lineHeight="undercut"
					text={"Definition Digital Brands Design + Communications"}
					color={"brand.gray.300"}
					size={{ base: "xl", md: "xl", lg: "xl" }}
					maxW={"xl"}
					threshold={0.2}
					triggerOnce={true}
					staggerChildren={0.02}
					id={"dfn1-titlesub1"}
				/>
				<div>
				<p>
						<strong>Adresse:</strong><br/>
						Rosenstraße 16<br/>
						26382 Wilhelmshaven<br/>
						Deutschland/Germany
					</p>			
					<br/>
					<p>
						<strong>Ansprechpartner & Kontakt:</strong><br/>
						Martin Adelstein<br/>
						E-Mail: martin.adelstein@definition.group<br/>
						Telefon: +49-170-2769711 <br/>
					</p>
				</div>
			</Container>
			<Container variant="layoutContainer" py={8} px={8}>
				<AnimatedText
					tag={"h4"}
					mb={4}
					lineHeight="undercut"
					text={"Color und Code Vertriebs GmbH - als Teil der definition.group"}
					color={"brand.gray.300"}
					size={{ base: "xl", md: "xl", lg: "xl" }}
					maxW={"xl"}
					threshold={0.2}
					triggerOnce={true}
					staggerChildren={0.02}
					id={"dfn1-titlesub1"}
				/>
				<div>
					<p>
						<strong>Adresse:</strong><br/>
						Am Hillernsen Hamm 51<br/>
						26441 Jever<br/>
						Deutschland/Germany
					</p>			
					<br/>
					<p>
						<strong>Ansprechpartner & Kontakt:</strong><br/>
						Martin Adelstein (Geschäftsführung)<br/>
						Christoph Gerken (Geschäftsführung)<br/>
						E-Mail: color-und-code@definition.group<br/>
					</p>	
				</div>
			</Container>
		</Layout>
	);
};

export default Index;
